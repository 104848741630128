import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '7278190723239'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        // "HALF CHEST WIDTH": [
        //     "",
        //     "23/58",
        //     "23½/60",
        //     "24½/62",
        //     "25/64",
        //     "26/66",
        //     "26½/68",
        //     "27½/70",
        //     "28/72"
        // ],
        // "LENGHT FROM CENTER BACK": [
        //     "",
        //     "27½/70",
        //     "28/72",
        //     "28/72",
        //     "28½/73",
        //     "29½/75",
        //     "30/76",
        //     "30¼/77",
        //     "30⅕/78"
        // ],
        // "SLEEVE LENGTH": [
        //     "",
        //     "21/54",
        //     "21½/55",
        //     "22/56",
        //     "22½/57",
        //     "22¾/58",
        //     "23/59",
        //     "23½/60",
        //     "24/61"
        // ],
        // "SHOULDER WIDTH": [
        //     "",
        //     "21½/55",
        //     "22/56",
        //     "22½/57",
        //     "22¾/58",
        //     "23/59",
        //     "23½/60",
        //     "24/61",
        //     "24½/62"
        // ]
    }


    return (
        <Layout>
            <SEO title="Shop - Cornelia Pant" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify